<template>
  <a :href="url" class="selection-tile">
    <img
      v-if="image"
      :src="image"
      :alt="imageAlt"
      class="selection-tile__image"
      data-test-id="selection-tile-image"
    />
    <div class="selection-tile__title" :data-test-id="titleTestId">{{ title }}</div>
    <div v-if="subtitle" class="selection-tile__subtitle" data-test-id="selection-tile-subtitle">
      {{ subtitle }}
    </div>
    <div
      v-if="description"
      class="selection-tile__description"
      data-test-id="selection-tile-description"
    >
      {{ description }}
    </div>
  </a>
</template>

<script>
import _ from 'lodash';
export default {
  name: 'SelectionTile',
  props: {
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: String,
    description: String,
    image: String,
    imageAlt: String,
  },

  computed: {
    titleTestId() {
      return `selection-tile-${_.kebabCase(this.title || 'unknown')}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.selection-tile {
  position: relative;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 0 9px rgba($shadow-default, 0.34);
  display: block;
  min-height: 330px;
  transition: all 0.2s;
  width: 250px;
  -webkit-font-smoothing: subpixel-antialiased;
  backface-visibility: hidden;
  text-align: center;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 30px 18px;

  &:hover {
    border: 1px solid $persian-green;
    box-shadow: 0 0 10px rgba($persian-green, 0.6);
    text-decoration: none;
    transform: scale(1.033);
  }
}

.selection-tile__title {
  color: $charcoal;
  font-size: 18px;
  font-weight: 500;
}

.selection-tile__subtitle {
  color: $charcoal;
  font-size: 15px;
}

.selection-tile__description {
  color: $charcoal;
  font-size: 13px;
  font-weight: lighter;
  margin-top: 15px;
}

.selection-tile__image {
  max-height: 120px;
  margin-bottom: 15px;
}
</style>
